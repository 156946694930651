import React from "react";
import classes from "./footer.module.css";
import logoImage from "../assets/Logo/Samer_design_white (3).svg";
import { Link } from "react-router-dom";
import { scroller } from "react-scroll";
import teqLogo from "../assets/logo-resto-dark@2x.png";

const Footer = () => {
  const scrollToNextSection = () => {
    scroller.scrollTo("ContactUs", { smooth: true, duration: 100 });
  };

  const scrollToNextSection3 = () => {
    scroller.scrollTo("AboutUs", { smooth: true, duration: 100 });
  };

  return (
    <div className={classes.footer}>
      <div className={classes.footerContacts}>
        <img className={classes.footerContactsImg} src={logoImage} alt="" />
        <div className={classes.social}>
          <p>All rights reserved to Samerdesign @ 2022, Developed by</p>
          <a target="_blank" href="https://teqneia.com/">
            <img className={classes.tqLogo} src={teqLogo} alt="" />
          </a>
        </div>
        <ul>
          <li>
            <Link to="/" onClick={scrollToNextSection3}>
              About us
            </Link>
          </li>
          <li>
            <Link
              to="/"
              onClick={scrollToNextSection}
              className={classes.contactUsLink}
            >
              Contact us
            </Link>
          </li>
          <li>
            <a
              target="_blank"
              href="https://www.facebook.com/Samer.Othman.Design"
            >
              {/* <img className={classes.imgFacebook} src={Facebook} alt="" /> */}
              Facebook
            </a>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default Footer;
