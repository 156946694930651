import React, { useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import classes from "./about.module.css";

import { Element } from "react-scroll";
import { useLocation } from "react-router-dom";

const AboutUs = () => {
  const location = useLocation();

  useEffect(() => {
    if (location.hash) {
      let elem = document.getElementById(location.hash.slice(1));
      if (elem) {
        elem.scrollIntoView({ behavior: "smooth" });
      }
    } else {
      window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    }
  }, [location]);

  // animation function
  const [animation, setAnimation] = useState(window.scrollY);
  const breakpoint = 200;
  const breakpoint2 = 500;

  useEffect(() => {
    window.addEventListener("scroll", () => setAnimation(window.scrollY));
  }, [animation]);

  return (
    <Element id="AboutUs">
      <div className={classes.values}>
        <h1>About Us</h1>
        <p
          className={`${classes.serP} ${
            animation > breakpoint && classes.serp_BackGround
          }`}
        >
          <span>Samer Othman for Engineering Design & Consultancy</span> is One
          of the best qualities of MEP systems design & shop drawings. We have
          experience in Gulf region for 10 years. Our team provides professional
          services in the Mechanical, Electrical & Plumbing (MEP) Engineering.
        </p>
        <p
          className={`${classes.serP} ${
            animation > breakpoint && classes.serp_BackGround
          }`}
        >
          We are capable of providing the best follow-up, technical support,
          project management & site supervision for our clients after tendering
          stage.
        </p>
        <div
          className={`${classes.values_conent} ${
            animation > breakpoint2 && classes.serDiv_BackGround
          }`}
        >
          <Container>
            <Row style={{ justifyContent: "center" }}>
              <Col md={4} className={classes.col}>
                <div className={classes.containerContent}>
                  <h1>Vision</h1>
                  <p>Providing Our Clients with Innovative Quality Solutions</p>
                </div>
              </Col>
              <Col md={4} className={classes.col}>
                <div className={classes.containerContent}>
                  <h1>Mission</h1>
                  <p>Be The MEP Consultant Of Choice.</p>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      </div>
    </Element>
  );
};

export default AboutUs;
