import React, { useState, useEffect } from "react";
import "bootstrap/dist/css/bootstrap.css";
import { Nav, Navbar } from "react-bootstrap";
// import logoImage from '../../assets/Logo/Samer_design_white.svg';
import logoImage from "../../assets/Logo/Samer_design_white (3).svg";
import { Link, useLocation } from "react-router-dom";
import { Element } from "react-scroll";
import { LinkContainer } from "react-router-bootstrap";
import "./NavbarComponent.css";

const NavbarComponent = ({ setModalOpen }) => {
  const [navbar, setNavbar] = useState(window.scrollY);
  const breakpoint = 66;

  useEffect(() => {
    window.addEventListener("scroll", () => setNavbar(window.scrollY));
  }, [navbar]);

  return (
    <Element name="NavbarComponent">
      <div className="header">
        <Navbar
          className={`
            navbar 
            ${useLocation().pathname === "/" && "navWithoutBackground"}
            ${navbar > breakpoint && "navBackground"}
            `}
          variant="dark"
          fixed="top"
          expand="lg"
          collapseOnSelect
        >
          <Navbar.Brand>
            <Link to="/">
              <img className="logo" src={logoImage} alt="" />
            </Link>
          </Navbar.Brand>
          <Navbar.Toggle className="navbarToggle" />
          <Navbar.Collapse className="navbarCollapse">
            <Nav className="nav">
              <LinkContainer to="/#AboutUs">
                <Nav.Link>About Us</Nav.Link>
              </LinkContainer>
              <LinkContainer to="/#Projects">
                <Nav.Link>Projects</Nav.Link>
              </LinkContainer>
              <LinkContainer to="/#ContactUs">
                <Nav.Link className="contactBtn">Contact Us</Nav.Link>
              </LinkContainer>
            </Nav>
          </Navbar.Collapse>
        </Navbar>
      </div>
    </Element>
  );
};

export default NavbarComponent;
