import React, { Component } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./ServicesMobile.css";

import { MdArrowRight } from "react-icons/md";

// icons
import logo1 from "../../assets/Icons/noun-electrical-2958711.svg";
import logo2 from "../../assets/Icons/noun-mechanical-2258582.svg";
import logo3 from "../../assets/Icons/noun-plumbing-3503103.svg";

export default class SimpleSlider extends Component {
  render() {
    const settings = {
      dots: true,
      infinite: true,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
      autoplay: true,
    };
    return (
      <div className="servicesMobile">
        <h1>Our Services</h1>
        <div className="servicesMobile_border"></div>
        <Slider className="slider" {...settings}>
          <div className="servicesMobile_content">
            <img src={logo2} alt="" />
            <p>Mechanical</p>
            <ul className="services_ul">
              <li>
                <MdArrowRight className="icon" />
                Heating, Ventilation & Air-Conditioning (HVAC)
              </li>
              <li>
                <MdArrowRight className="icon" />
                Firefighting
              </li>
            </ul>
          </div>
          <div className="servicesMobile_content">
            <img src={logo1} alt="" />
            <p>Electrical</p>
            <ul className="services_ul">
              <li>
                <MdArrowRight className="icon" />
                Medium Voltage
              </li>
              <li>
                <MdArrowRight className="icon" />
                Low Voltage
              </li>
              <li>
                <MdArrowRight className="icon" />
                Lighting
              </li>
              <li>
                <MdArrowRight className="icon" />
                Telecommunications & Security systems
              </li>
            </ul>
          </div>
          <div className="servicesMobile_content">
            <img src={logo3} alt="" />
            <p>Plumbing</p>
            <ul className="services_ul">
              <li>
                <MdArrowRight className="icon" />
                Water Supply
              </li>
              <li>
                <MdArrowRight className="icon" />
                Drainage
              </li>
              <li>
                <MdArrowRight className="icon" />
                Irrigation
              </li>
              <li>
                <MdArrowRight className="icon" />
                Pools & fountains
              </li>
              <li>
                <MdArrowRight className="icon" />
                Water Treatment Plants
              </li>
            </ul>
          </div>
        </Slider>
      </div>
    );
  }
}
