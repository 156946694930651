import React, { useEffect } from "react";
import { Col, Container, Row } from "react-bootstrap";
import classes from "./projectsPage.module.css";
import Rectangle from "../../assets/WALEED-DOOS PALACE & REST HOUSE 2.jpg";
import Rectangle4 from "../../assets/WALEED-DOOS PALACE & REST HOUSE 1.jpg";
import Rectangle1 from "../../assets/SHEIKH SULTAN AL-ATHEL RESIDENTIAL COMPOUND 2-min.jpg";
import Rectangle5 from "../../assets/SHEIKH SULTAN AL-ATHEL RESIDENTIAL COMPOUND 1.jpg";
import Rectangle2 from "../../assets/NAYEF ALATHEL 1.jpg";
import Rectangle6 from "../../assets/NAYEF ALATHEL 2.jpg";
import Rectangle3 from "../../assets/AL-OTHMAN PALACE 2.jpg";
import Rectangle7 from "../../assets/AL-OTHMAN PALACE 1.jpg";
import Rectangle8 from "../../assets/AL-KHARSAN RESIDENTIAL COMPOUND 1.jpg";
import Rectangle9 from "../../assets/AL-KHARSAN RESIDENTIAL COMPOUND 2.jpg";
import Rectangle10 from "../../assets/ABU TURKI PALACE 1.jpg";
import Rectangle11 from "../../assets/ABU TURKI PALACE 2.jpg";

import { useLocation } from "react-router-dom";
import { Element } from "react-scroll";

import Slider from "react-slick";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import "./servicesSlide.css";

const Villas = () => {
  const location = useLocation();

  useEffect(() => {
    if (location.pathname) {
      let elem = document.getElementById(location.pathname);
      if (elem) {
        elem.scrollIntoView({ behavior: "smooth" });
      }
    } else {
      window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    }
  }, [location.pathname]);

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
  };

  return (
    <Element id="/palaces">
      <Container fluid className={classes.projectPageContainer}>
        <div className={classes.projectPage}>
          <div className={classes.project_conent}>
            <span className={classes.hotelsTitle}>PALACES & COMPOUNDS</span>
            <Row>
              <Col md={12} className={classes.col}>
                <ul className={classes.ul}>
                  <li className={classes.liFirst}>
                    ABU TURKI PALACE, RIYADH - KSA, 2022
                  </li>
                  <li className={classes.liFirst}>
                    SHEIKH SULTAN AL-ATHEL RESIDENTIAL COMPOUND, RIYADH - KSA,
                    2021
                  </li>
                  <li className={classes.liFirst}>
                    WALEED-DOOS PALACE & REST HOUSE, RIYADH - KSA, 2021.
                  </li>
                  <li className={classes.liFirst}>
                    ABDULRAHMAN AL-KHARSAN RESIDENTIAL COMPOUND, RIYADH - KSA,
                    2021
                  </li>
                  <li className={classes.liFirst}>
                    NAYEF AL-ATHEL PALACE, RIYADH - KSA, 2020
                  </li>
                  <li className={classes.liFirst}>
                    AL-OTHMAN PALACE, RIYADH - KSA, 2019
                  </li>
                </ul>
              </Col>
            </Row>
          </div>
          <div className={classes.project_content_1}>
            <Row className={classes.row}>
              <Col
                md={6}
                className={classes.col1}
                xs={{ order: 2 }}
                lg={{ order: 1 }}
                style={{ display: "flex", justifyContent: "flex-end" }}
              >
                <ul className={classes.ul}>
                  <li style={{ display: "block", textAlign: "right" }}>
                    ABU TURKI PALACE
                  </li>
                  <li style={{ display: "block", textAlign: "right" }}>
                    RIYADH - KSA
                  </li>
                  <li style={{ display: "block", textAlign: "right" }}>2022</li>
                </ul>
              </Col>
              <Col
                md={6}
                className={classes.col}
                xs={{ order: 1 }}
                lg={{ order: 2 }}
              >
                <Slider {...settings}>
                  <img src={Rectangle10} alt="" />
                  <img src={Rectangle11} alt="" />
                </Slider>
              </Col>
            </Row>
          </div>
          <div className={classes.project_content_1}>
            <Row className={classes.row}>
              <Col md={6} className={classes.col}>
                <Slider {...settings}>
                  <img src={Rectangle} alt="" />
                  <img src={Rectangle4} alt="" />
                </Slider>
              </Col>
              <Col md={6} className={classes.col1} style={{ display: "flex" }}>
                <ul className={classes.ul}>
                  <li>WALEED-DOOS PALACE & REST HOUSE</li>
                  <li>RIYADH - KSA</li>
                  <li>2021</li>
                </ul>
              </Col>
            </Row>
          </div>
          <div className={classes.project_content_1}>
            <Row className={classes.row}>
              <Col
                md={6}
                className={classes.col1}
                xs={{ order: 2 }}
                lg={{ order: 1 }}
                style={{ display: "flex", justifyContent: "flex-end" }}
              >
                <ul className={classes.ul}>
                  <li style={{ display: "block", textAlign: "right" }}>
                    SHEIKH SULTAN AL-ATHEL RESIDENTIAL COMPOUND
                  </li>
                  <li style={{ display: "block", textAlign: "right" }}>
                    RIYADH - KSA
                  </li>
                  <li style={{ display: "block", textAlign: "right" }}>2021</li>
                </ul>
              </Col>
              <Col
                md={6}
                className={classes.col}
                xs={{ order: 1 }}
                lg={{ order: 2 }}
              >
                <Slider {...settings}>
                  <img src={Rectangle1} alt="" />
                  <img src={Rectangle5} alt="" />
                </Slider>
              </Col>
            </Row>
          </div>
          <div className={classes.project_content_1}>
            <Row className={classes.row}>
              <Col md={6} className={classes.col}>
                <Slider {...settings}>
                  <img src={Rectangle8} alt="" />
                  <img src={Rectangle9} alt="" />
                </Slider>
              </Col>
              <Col md={6} className={classes.col1} style={{ display: "flex" }}>
                <ul className={classes.ul}>
                  <li>ABDULRAHMAN AL-KHARSAN RESIDENTIAL COMPOUND</li>
                  <li>RIYADH - KSA</li>
                  <li>2021</li>
                </ul>
              </Col>
            </Row>
          </div>
          <div className={classes.project_content_1}>
            <Row className={classes.row}>
              <Col
                md={6}
                className={classes.col1}
                xs={{ order: 2 }}
                lg={{ order: 1 }}
                style={{ display: "flex", justifyContent: "flex-end" }}
              >
                <ul className={classes.ul}>
                  <li style={{ display: "block", textAlign: "right" }}>
                    NAYEF AL-ATHEL
                  </li>
                  <li style={{ display: "block", textAlign: "right" }}>
                    RIYADH - KSA
                  </li>
                  <li style={{ display: "block", textAlign: "right" }}>2020</li>
                </ul>
              </Col>
              <Col
                md={6}
                className={classes.col}
                xs={{ order: 1 }}
                lg={{ order: 2 }}
              >
                <Slider {...settings}>
                  <img src={Rectangle2} alt="" />
                  <img src={Rectangle6} alt="" />
                </Slider>
              </Col>
            </Row>
          </div>
          <div className={classes.project_content_1}>
            <Row className={classes.row}>
              <Col md={6} className={classes.col}>
                <Slider {...settings}>
                  <img src={Rectangle3} alt="" />
                  <img src={Rectangle7} alt="" />
                </Slider>
              </Col>
              <Col md={6} className={classes.col1} style={{ display: "flex" }}>
                <ul className={classes.ul}>
                  <li>PALACE AL-OTHMAN PALACE</li>
                  <li>RIYADH - KSA</li>
                  <li>2019</li>
                </ul>
              </Col>
            </Row>
          </div>
        </div>
      </Container>
    </Element>
  );
};

export default Villas;
