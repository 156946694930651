import { useEffect, useState } from "react";
import NavbarComponent from "./components/home/NavbarComponent";
import Projects from "./components/home/Projects";
import ProjectsMobile from "./components/home/ProjectsMobile";
import Services from "./components/home/Services";
import Values from "./components/home/Values";
import ValuesMobile from "./components/home/ValuesMobile";
import ServicesMobile from "./components/home/ServicesMobile";
import Contact from "./components/home/Contact";
import Footer from "./components/Footer";
// import Header from "./components/home/Header";
import Modal from "./components/modal/Modal";
import { Switch, Route } from "react-router-dom";
import Villas from "./components/projects/Villas";
import Others from "./components/projects/Others";
import Malls from "./components/projects/Malls";
import Hotels from "./components/projects/Hotels";
import AboutUs from "./components/home/AboutUs";
import { Toaster } from "react-hot-toast";
import HeaderVideo from "./components/home/HeaderVideo";
import "./index.css";

function App() {
  const [modalOpen, setModalOpen] = useState(false);

  const [width, setWidth] = useState(window.innerWidth);
  const breakpoint = 620;

  useEffect(() => {
    window.addEventListener("resize", () => setWidth(window.innerWidth));
  }, [width]);

  //VISIT REGISTRATION CURRENTLY DISABLE ON THE BACKEND
  // useEffect(() => {
  //   const registerVisit = async () => {
  //     await fetch("https://api.ipify.org?format=json")
  //       .then((response) => response.json())
  //       .then((data) => {
  //         const ipAddress = data.ip;
  //         const query = window.location.search;
  //         const params = new URLSearchParams(query);
  //         const source = params.get("s");

  //         const formData = new FormData();
  //         formData.append("source_website", document.referrer);
  //         formData.append("page", window.location.href);
  //         formData.append("ip", ipAddress);
  //         if (!source === null) {
  //           formData.append("reference", source);
  //         }

  //         fetch("https://py.dws.teqneia.com/create-report/?tenant_id=5", {
  //           method: "POST",
  //           body: formData,
  //         }).then((response) => {
  //           if (!response.ok) {
  //             console.error("Unable to register visit.");
  //           }
  //           return response.json();
  //         });

  //         params.delete("source");
  //         const newUrl = window.location.pathname;
  //         // eslint-disable-next-line no-restricted-globals
  //         history.replaceState(null, null, newUrl);
  //       });
  //   };
  //   registerVisit();
  // }, []);

  return (
    <>
      <NavbarComponent setModalOpen={setModalOpen} />
      <Switch>
        <Route path="/" exact>
          {/* <Header setModalOpen={setModalOpen} /> */}
          <HeaderVideo />
          <AboutUs />
          {width < breakpoint ? <ProjectsMobile /> : <Projects />}
          {width < breakpoint ? <ValuesMobile /> : <Values />}
          {width < breakpoint ? <ServicesMobile /> : <Services />}
          <Contact />
        </Route>
        <Route path="/palaces" exact>
          <Villas />
        </Route>
        <Route path="/Others" exact>
          <Others />
        </Route>
        <Route path="/Malls" exact>
          <Malls />
        </Route>
        <Route path="/Hotels" exact>
          <Hotels />
        </Route>
      </Switch>
      <Footer />
      {modalOpen && <Modal setModalOpen={setModalOpen} />}
      <Toaster
        position="bottom-right"
        toastOptions={{
          style: {
            fontSize: "1rem",
          },
        }}
      />
    </>
  );
}

export default App;
