import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import classes from "./Values.module.css";
import logo1 from "../../assets/noun-integrity-3685349.svg";
import logo2 from "../../assets/noun-idea-3913680.svg";
import logo3 from "../../assets/noun-integrity-4636822.svg";
import logo4 from "../../assets/noun-enjoyment-4065784.svg";
import logo5 from "../../assets/noun-empowering-2202388.svg";
import logo6 from "../../assets/noun-social-responsibility-4388343.svg";

const Values = () => {
  return (
    <div className={classes.values}>
      <h1>Our Values</h1>
      <div className={classes.values_conent}>
        <Container>
          <Row>
            <Col md={4} className={classes.col}>
              <div className={classes.containerContent}>
                <img src={logo1} alt="" />
                <p>Respect and integrity</p>
              </div>
            </Col>
            <Col md={4} className={classes.col}>
              <div className={classes.containerContent}>
                <img src={logo2} alt="" />
                <p>Creativity and innovation</p>
              </div>
            </Col>
            <Col md={4} className={classes.col}>
              <div className={classes.containerContent}>
                <img src={logo3} alt="" />
                <p>Teamwork and trust</p>
              </div>
            </Col>
            <Col md={4} className={classes.col} style={{ marginTop: "10px" }}>
              <div className={classes.containerContent}>
                <img src={logo4} alt="" />
                <p>Professionalism and excellence</p>
              </div>
            </Col>
            <Col md={4} className={classes.col} style={{ marginTop: "10px" }}>
              <div className={classes.containerContent}>
                <img src={logo5} alt="" />
                <p>Care and empowerment to our partners</p>
              </div>
            </Col>
            <Col md={4} className={classes.col} style={{ marginTop: "10px" }}>
              <div className={classes.containerContent}>
                <img src={logo6} alt="" />
                <p>Environmental and social responsibility</p>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </div>
  );
};

export default Values;
