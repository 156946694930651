import React from "react";
import classes from "./modal.module.css";

const Modal = ({ setModalOpen }) => {
  return (
    <div className={classes.wrapper}>
      <div className={classes.container}>
        <div className={classes.contact_form}>
          <h1>Get A Quote</h1>
          <form className={classes.form}>
            <div className={classes.inputName}>
              <label htmlFor="name">First & Last Name</label>
              <input type="text" />
            </div>
            <div className={classes.inputNumber}>
              <div className={classes.inputNameContainer}>
                <label htmlFor="number">Phone number</label>
                <input type="text" />
              </div>
              <div className={classes.inputNameContainer}>
                <label htmlFor="email">Email address</label>
                <input type="text" />
              </div>
            </div>
            <div className={classes.inputName}>
              <label htmlFor="name">Company name (optional)</label>
              <input type="text" />
            </div>
            <div className={classes.inputName}>
              <span>Message</span>
              <textarea
                name=""
                id=""
                cols="30"
                rows="10"
                placeholder="Your message here..."
              ></textarea>
            </div>
            <div className={classes.buttons}>
              <button>Send</button>
              <button type="button" onClick={() => setModalOpen(false)}>
                Cancle
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default Modal;
